import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import {
  getCustomer,
  putCustomer,
  getOpportunities,
  createOpportunity,
  editOpportunity,
  createCustomer,
} from "../../libs/api";
import Purchaser from "./forms/purchaser";
import EmergencyContact from "./forms/emergencyContact";
import Contexts from "../../contexts";
import { AddPurchaser, InfoFilled, Back, CircleChecked } from "../../images";
import BallotingTime from "../../components/ballotingTime";
import UnitSelectionTime from "../../components/unitSelectionTime";

function Register() {
  const history = useHistory();
  const { user, isRegistered, opportunity, setOpportunity } = useContext(
    Contexts
  );
  const customerId = user.attributes["custom:lead_customer_id"];

  const [loading, setLoading] = useState(true);
  const [registrar, setRegistrar] = useState({});
  const [purchasers, setPurchasers] = useState([]);
  const [emergency_contacts, setEmergencyContact] = useState([
    {
      relation: "",
      name: "",
      nric: "",
      email: "",
      mobile: "",
    },
  ]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function populateData() {
      const opp = await getOpportunities(
        user.attributes["custom:lead_customer_id"]
      );

      const currentOpps = _.filter(opp, {
        project_id: process.env.REACT_APP_PROJECT_ID,
      });

      setOpportunity(currentOpps);
      const res = await getCustomer(customerId);
      const shouldBlockIdentity = res.contact.identity ? true : false;
      setRegistrar({ identity_type: "1", ...res.contact, shouldBlockIdentity });
      if (res.contact && res.contact.emergency_contacts) {
        setEmergencyContact([
          {
            mobile: res.contact.emergency_contacts[0].mobile,
            email: res.contact.emergency_contacts[0].email,
            name: res.contact.emergency_contacts[0].name,
            relation: res.contact.emergency_contacts[0].relation,
            nric: res.contact.emergency_contacts[0].nric,
          },
        ]);
      }

      if (
        !_.isEmpty(currentOpps) &&
        currentOpps[0].customer_ids &&
        !_.isEmpty(currentOpps[0].customer_ids)
      ) {
        const sub_purchasers = await Promise.all(
          _.map(currentOpps[0].customer_ids, async (customer_id) => {
            const purchaser = await getCustomer(customer_id);
            return {
              identity_type: "1",
              ...purchaser.contact,
              customer_id: purchaser.id,
            };
          })
        );
        setPurchasers(sub_purchasers);
      }
      setLoading(false);
    }

    populateData();
  }, [customerId]);

  function _onTextChange(name, value, no) {
    if (no) {
      const data = [...purchasers];
      data[no - 1][name] = value;
      setPurchasers(data);
    } else {
      setRegistrar({ ...registrar, [name]: value });
    }
  }

  function _onObjectChange(object, no) {
    if (no) {
      const data = [...purchasers];
      data[no - 1] = {
        ...data[no - 1],
        ...object,
      };
      setPurchasers(data);
    } else {
      setRegistrar({ ...registrar, ...object });
    }
  }

  function _onEmergencyContactChange(name, value) {
    if (name === "mobile") {
      setEmergencyContact([
        {
          ...emergency_contacts[0],
          ...value,
        },
      ]);
    } else {
      setEmergencyContact([
        {
          ...emergency_contacts[0],
          [name]: value,
        },
      ]);
    }
  }

  function _addPurchaser() {
    setPurchasers(_.concat(purchasers, { identity_type: "1" }));
  }

  function _removePurchaser(index) {
    const data = [...purchasers];
    data.splice(index, 1);
    setPurchasers(data);
  }

  async function _submit() {
    const ic_errors = _.map([registrar, ...purchasers], (purchaser, index) => {
      if (!purchaser.ic_front || !purchaser.ic_back) {
        return {
          index,
          error: purchaser.ic_front
            ? purchaser.ic_back
              ? ""
              : "Please upload IC Back"
            : "Please upload IC Front",
        };
      }
    });
    const final_ic_errors = _.filter(ic_errors);
    if (!_.isEmpty(final_ic_errors)) {
      alert(
        `Purchaser ${final_ic_errors[0].index + 1} : ${
          final_ic_errors[0].error
        }`
      );
    } else {
      if (!isSubmitting) {
        const form = $("#registration-form")[0];
        if (form.checkValidity()) {
          setIsSubmitting(true);
          const ballot_id =
            registrar.name.replace(/\s+/, "").substring(0, 3) +
            registrar.identity.slice(registrar.identity.length - 4);
          const data = {
            id: customerId,
            ...registrar,
            ballot_id,
            emergency_contacts,
          };

          const err = await putCustomer(customerId, data);

          if (err) {
            console.log("@Putting customer error", err);
            alert(err.error_message);
            setIsSubmitting(false);
            return;
          }

          const customer_ids = await Promise.all(
            _.map(purchasers, async (purchaser, index) => {
              delete purchaser.updated_at;
              if (purchaser.customer_id) {
                const error = await putCustomer(
                  purchaser.customer_id,
                  purchaser
                );
                if (error) {
                  return { error, index };
                } else {
                  return purchaser.customer_id;
                }
              } else {
                const { id, error } = await createCustomer(
                  purchaser,
                  user.attributes["custom:company_id"]
                );
                if (error) {
                  return { error, index };
                } else {
                  return id;
                }
              }
            })
          );

          const customer_ids_errors = _.filter(customer_ids, "error");
          if (!_.isEmpty(customer_ids_errors)) {
            alert(
              `Purchaser ${customer_ids_errors[0].index + 2} : ${
                customer_ids_errors[0].error.error_message
              }`
            );
            setIsSubmitting(false);
          } else {
            if (isRegistered) {
              const opportunityErr = await editOpportunity(
                opportunity[0].id,
                customerId,
                user.attributes["custom:company_id"],
                customer_ids
              );

              if (opportunityErr) {
                console.log("@Creating Oppoturnity error", opportunityErr);
                setIsSubmitting(false);
              } else {
                setIsUpdated(true);
                setIsSubmitting(false);
              }
            } else {
              const opportunityErr = await createOpportunity(
                customerId,
                registrar.email,
                registrar.name,
                user.attributes["custom:company_id"],
                customer_ids
              );

              if (opportunityErr) {
                console.log("@Creating Oppoturnity error", opportunityErr);
                setIsSubmitting(false);
              } else {
                history.push("/1/detail/thanks");
              }
            }
          }
        } else {
          form.reportValidity();
        }
      } else {
        console.log("Submitting");
      }
    }
  }

  if (loading) {
    return (
      <div className="container">
        <div className="row">
          <div className="col vh-100 d-flex justify-content-center align-items-center">
            <FontAwesomeIcon icon={faSpinner} spin size="2x" />
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="container-fluid mt-4" style={{ marginBottom: 100 }}>
      <div className="row mb-4">
        <div className="col col-3">
          <Link
            to={{ pathname: "/1/detail/online-balloting" }}
            style={{
              fontFamily: "Open Sans",
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: 14,
              color: "#596975",
            }}
            className="d-flex align-items-center justify-content-start"
          >
            <img src={Back} alt="Back" width={6} className="mr-3" />
            Back
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <span className="title">
            Online Balloting and Unit Selection for Starling, 12B @ Bandar
            Rimbayu
          </span>
        </div>
        <div className="col-md-6">
          <div className="d-flex mt-2">
            <div>
              <BallotingTime showLinks={false} />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex mt-2">
            <div>
              <UnitSelectionTime showLinks={false} />
            </div>
          </div>
        </div>
        <div className="col-12 mt-4 mb-2">
          <div className="notify-container">
            <img src={InfoFilled} alt="exclamation icon" width={20} />{" "}
            <span className="notify">
              Complete these details to ensure smooth balloting process.
            </span>
          </div>
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <span className="sub-title">Personal details</span>
          <br />
          <br />
        </div>
      </div>
      <form id="registration-form">
        <Purchaser
          no={0}
          show={true}
          purchaser={registrar}
          onTextChange={_onTextChange}
          onObjectChange={_onObjectChange}
        />
        {_.map(purchasers, (purchaser, index) => {
          return (
            <Purchaser
              key={index + 1}
              no={index + 1}
              show={true}
              onTrashButtonPress={_removePurchaser}
              purchaser={purchaser}
              onTextChange={_onTextChange}
              onObjectChange={_onObjectChange}
            />
          );
        })}
        <div className="row mt-3">
          <div className="col-md-12">
            <button
              type="button"
              onClick={_addPurchaser}
              className="add-purchaser d-flex align-items-center"
            >
              <img
                src={AddPurchaser}
                alt="Add Purchaser Button"
                width={18}
                className="mr-1"
              />{" "}
              Add Purchaser
            </button>
          </div>
        </div>
        <EmergencyContact
          emergency_contact={emergency_contacts[0]}
          _onEmergencyContactChange={_onEmergencyContactChange}
        />
        {isUpdated ? (
          <div className="row mt-4">
            <div className="col">
              <div className="alert alert-success alert-dismissible text-center">
                <button
                  className="close"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsUpdated(false);
                  }}
                >
                  &times;
                </button>
                <div className="d-flex ">
                  <div style={{ width: 20 }}>
                    <img src={CircleChecked} alt="success" width={20} />
                  </div>
                  <div className="flex-fill">
                    You have successfully updated your registration details.
                    <Link
                      to={{
                        pathname: "/1/detail",
                        scrollToTop: true,
                      }}
                      style={{ marginLeft: 10, textDecoration: "underline" }}
                    >
                      Go to Starling, 2B
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className="row mt-3">
          <div className="col-12 d-flex justify-content-center">
            {/* <span
              style={{
                fontFamily: "Open Sans",
                fontSize: 12,
                textAlign: "center",
                color: "#00233B",
              }}
            >
              By clicking to the Submit button, I agree to the{" "}
              <a href="/terms" style={{ color: "#49B3B1" }}>
                Terms and Conditions
              </a>
              .
            </span> */}
          </div>
          <div className="col-md-2 offset-md-5 mb-4">
            <button
              onClick={(e) => {
                e.preventDefault();
                _submit();
              }}
              className="blue-button mt-4"
            >
              {isSubmitting ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : isRegistered ? (
                "Update"
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Register;
