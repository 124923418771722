import React, { useState } from "react";
import { LogoStarling } from "../images";

function ContactUs() {
  const [contactVisibility, setContactVisibility] = useState(false);
  const [emailVisibility, setEmailVisibility] = useState(false);

  return (
    <>
      <span className="contact-us">Contact Us</span>
      <div className="mt-3">
        <div className="d-flex align-items-center">
          <img
            alt="Logo Starling"
            src={LogoStarling}
            width="50px"
            style={{ verticalAlign: "middle" }}
          />
          <span className="sub-info">Starling @ Bandar Rimbayu</span>
        </div>
        <button
          className="grey-outline-button mt-4 w-100"
          onClick={() => setContactVisibility(true)}
        >
          {contactVisibility ? (
            <a href="tel:1800228686">1800 22 8686</a>
          ) : (
            "Contact"
          )}
        </button>
        <button
          className="grey-outline-button mt-3 w-100"
          onClick={() => setEmailVisibility(true)}
        >
          {emailVisibility ? (
            <a href="mailto:sales.rimbayu@ijm.com">sales.rimbayu@ijm.com</a>
          ) : (
            "Email"
          )}
        </button>
      </div>
    </>
  );
}

export default ContactUs;
