import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import { Calendar, Video, MapMarker } from "../images";
import Contexts from "../contexts";

function UnitSelectionTime({ showLinks }) {
  const { isRegistered, opportunity } = useContext(Contexts);

  const [unitSelectionStartTime, setUnitSelectionStartTime] = useState(null);
  const [unitSelectionEndTime, setUnitSelectionEndTime] = useState(null);
  const [unitSelectionVideoUrl, setUnitSelectionVideoUrl] = useState(null);

  useEffect(() => {
    if (!_.isEmpty(opportunity)) {
      _.map(opportunity, (booking) => {
        if (!_.isEmpty(booking.appointments)) {
          setUnitSelectionStartTime(
            moment(booking.appointments[0]["starting_at"]).format("H:mmA")
          );
          setUnitSelectionEndTime(
            moment(booking.appointments[0]["ending_at"]).format("H:mmA")
          );
          setUnitSelectionVideoUrl(booking.appointments[0].meeting_url);
        }
      });
    }
  }, [opportunity]);

  const renderLinks = () => {
    if (isRegistered && showLinks) {
      return (
        <div className="col-12 col-md-8 d-flex align-items-center pl-0 pl-special">
          <div className="row">
            <div className="col-auto">
              <div className="dropdown">
                <button
                  className="btn btn-link dropdown-toggle pl-0"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img alt="Calendar" src={Calendar} width={24} />
                  <span className="mx-1 dropdown-title">Add to Calendar</span>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <a
                    className="dropdown-item dropdown-option"
                    href="https://www.google.com/calendar/render?action=TEMPLATE&text=Starling%2C%2012D%20%40%20Bandar%20Rimbayu%20Unit%20Selection&dates=20210110/20210111&details=Dear%20Customer%2C%0A%0AA%20link%20to%20a%20video%20conference%20call%20will%20be%20sent%20to%20you.%20Join%20the%20call%20at%20the%20allocated%20time%20on%2010th%20of%20January%202021.%20You%20will%20be%20given%2020%20minutes%20to%20select%20your%20ideal%20unit.%0A%0AWe%20look%20forward%20to%20your%20participation.%0ABandar%20Rimbayu&location=Online%20conference%20call&trp=true&sf=true&output=xml#f"
                    target="_blank"
                  >
                    Google Calendar
                  </a>
                  <a
                    className="dropdown-item dropdown-option"
                    href="https://microsites.mhub.my/ijm/emails/2021-01-10-Bandar_Rimbayu_UnitSelection4.ics"
                    download
                  >
                    Outlook Calendar (.ics)
                  </a>
                  <a
                    className="dropdown-item dropdown-option"
                    href="https://calendar.yahoo.com/?desc=Dear%20Customer%2C%0A%0AA%20link%20to%20a%20video%20conference%20call%20will%20be%20sent%20to%20you.%20Join%20the%20call%20at%20the%20allocated%20time%20on%2010th%20of%20January%202021.%20You%20will%20be%20given%2020%20minutes%20to%20select%20your%20ideal%20unit.%0A%0AWe%20look%20forward%20to%20your%20participation.%0ABandar%20Rimbayu&st=20210110&title=Starling%2C%2012D%20%40%20Bandar%20Rimbayu%20Unit%20Selection&v=60&dur=allday"
                    target="_blank"
                  >
                    Yahoo Calendar
                  </a>
                </div>
              </div>
            </div>
            {isRegistered && unitSelectionVideoUrl ? (
              <>
                <div
                  className="d-none d-xl-block"
                  style={{ border: "1px solid #DBDDDE" }}
                ></div>
                <div className="col-auto">
                  <a
                    className="btn btn-link pl-0 text-left"
                    target="_blank"
                    href={unitSelectionVideoUrl}
                  >
                    <img alt="Video" src={Video} width={24} />
                    <span className="mx-1 dropdown-title">
                      Join Google Meet Video Conference
                    </span>
                  </a>
                </div>
              </>
            ) : null}
          </div>
        </div>
      );
    }
  };
  return (
    <div className="row">
      <div className="col-auto">
        <div className="d-flex flex-column align-items-center justify-content-center date-box">
          <span className="month">Jan</span>
          <span className="date">10</span>
        </div>
      </div>
      <div className="col">
        <div className="row">
          <div className="col-auto col-md-3">
            <div className="d-flex flex-column justify-content-center">
              <span className="date-title">Unit Selection</span>
              <span className="sub-info">
                {unitSelectionStartTime
                  ? `${unitSelectionStartTime} - ${unitSelectionEndTime}`
                  : "Timeslot to be confirmed"}
              </span>
            </div>
          </div>
          {renderLinks()}
        </div>
      </div>
    </div>
  );
}

export default UnitSelectionTime;

UnitSelectionTime.defaultProps = {
  showLinks: true,
};
