import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Checked } from "../../images";
import Contexts from "../../contexts";
import _ from "lodash";
import { getOpportunities,getCustomer } from "../../libs/api";
import BallotingTime from "../../components/ballotingTime";
import UnitSelectionTime from "../../components/unitSelectionTime";

function Thankyou() {
  const { user, setCustomer, setIsRegistered } = useContext(Contexts);
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchStatus = async () => {
      const opp = await getOpportunities(
        user.attributes["custom:lead_customer_id"]
      );

      const customer = await getCustomer(
        user.attributes["custom:lead_customer_id"]
      );
      setCustomer(customer)
      
      if (
        !_.isEmpty(
          _.filter(opp, {
            project_id: process.env.REACT_APP_PROJECT_ID,
          })
        )
      ) {
        setIsRegistered(true);
      }
    };
    fetchStatus();
  }, []);

  return (
    <div
      className="container-fluid"
      style={{ marginTop: 60, marginBottom: 100 }}
    >
      <div className="row">
        <div
          className="col-md-8 offset-md-2 col-sm-12 mt-3 mb-3"
          style={{ textAlign: "center" }}
        >
          <img alt="Checked" src={Checked} width={60} />
          <div className="d-flex flex-column mt-4">
            <span className="title">
              Thank you for registering for Online Balloting and Unit Selection
              for Starling @ Bandar Rimbayu
            </span>
            <span className="sub-info">
              We will notify you the timeslot for your unit selection session
              here and on email. Stay tune!
            </span>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12 col-md-8 offset-md-2">
          <div className="mt-3">
            <BallotingTime />
          </div>
          <div className="mt-3">
            <UnitSelectionTime />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col text-center">
          <Link
            to={{ pathname: "/1/detail" }}
            className="btn grey-outline-button mt-4"
          >
            Go to Starling 12B
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Thankyou;
