import React, { useContext } from "react";
import { Calendar, Video } from "../images";
import Contexts from "../contexts";
import _ from "lodash";

function BallotingTime({ showLinks }) {
  const { isRegistered, customer } = useContext(Contexts);

  const renderLinks = () => {
    if (isRegistered && showLinks) {
      return (
        <div className="col-12 col-md-8 d-flex align-items-center pl-0 pl-special">
          <div className="row">
            <div className="col-auto">
              <div className="dropdown">
                <button
                  className="btn btn-link dropdown-toggle pl-0"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img alt="Calendar" src={Calendar} width={24} />
                  <span className="mx-1 dropdown-title">Add to Calendar</span>
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                  <a
                    className="dropdown-item dropdown-option"
                    href="https://calendar.google.com/event?action=TEMPLATE&tmeid=NWw1anF1bnM2Z29laGNvdDZsM2ltMGtuN3AgbWh1Yi5teV9namNvZjJxZmo0MTgwcGprZXJtaGFvdWM4a0Bn&tmsrc=mhub.my_gjcof2qfj4180pjkermhaouc8k%40group.calendar.google.com"
                    target="_blank"
                  >
                    Google Calendar
                  </a>
                  <a
                    className="dropdown-item dropdown-option"
                    href="https://box.mhub.my/media/2020-07-09-Bandar_Rimbayu_Online_Balloting-Jul25.ics"
                    // href={`${process.env.PUBLIC_URL}/ics/Bandar_Rimbayu_Online_Balloting.ics`}
                    download
                  >
                    Outlook Calendar (.ics)
                  </a>
                  <a
                    className="dropdown-item dropdown-option"
                    href="https://calendar.yahoo.com/?desc=Watch%20on%20Facebook%21%20https%3A%2F%2Fwww.facebook.com%2FBandarRimbayuIJM%2F&st=20200725T020000Z&dur=0200&title=Starling%2C%2012B%20%40%20Bandar%20Rimbayu%20Online%20Balloting&v=60"
                    target="_blank"
                  >
                    Yahoo Calendar
                  </a>
                </div>
              </div>
            </div>
            <div
              className="d-none d-xl-block"
              style={{ border: "1px solid #DBDDDE" }}
            ></div>
            <div className="col-auto">
              <a
                className="btn btn-link pl-0 text-left"
                target="_blank"
                href="https://www.facebook.com/BandarRimbayuIJM/"
              >
                <img alt="Video" src={Video} width={24} />
                <span className="mx-1 dropdown-title">Watch on Facebook</span>
              </a>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="row">
      <div className="col-auto">
        <div className="d-flex flex-column align-items-center justify-content-center date-box">
          <span className="month">July</span>
          <span className="date">25</span>
        </div>
      </div>
      <div className="col">
        <div className="row">
          <div className="col-auto col-md-3">
            <div className="d-flex flex-column justify-content-center">
              <span className="date-title">Online Balloting</span>
              <span className="sub-info">10:00AM - 12:00PM</span>
              {isRegistered && customer ? (
                <span className="sub-info">
                  Ballot ID:{" "}
                  <b className="text-uppercase">{customer.contact.ballot_id}</b>
                </span>
              ) : null}
            </div>
          </div>
          {renderLinks()}
        </div>
      </div>
    </div>
  );
}

export default BallotingTime;

BallotingTime.defaultProps = {
  showLinks: true,
};
