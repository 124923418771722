import React, { useContext, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Contexts from "../../contexts";
import { TopView } from "../../images";
import { Calendar, Video, MapMarker } from "../../images";

function Listing() {
  const { isRegistered, opportunity, customer } = useContext(Contexts);
  const [unitSelectionStartTime, setUnitSelectionStartTime] = useState(null);
  const [unitSelectionEndTime, setUnitSelectionEndTime] = useState(null);
  const [unitSelectionVideoUrl, setUnitSelectionVideoUrl] = useState(null);
  const history = useHistory();
  const view = (e) => {
    if (isRegistered) {
      history.push("/1/detail");
    } else {
      history.push("/1/detail/online-balloting");
    }
  };

  useEffect(() => {
    if (!_.isEmpty(opportunity)) {
      _.map(opportunity, (booking) => {
        if (!_.isEmpty(booking.appointments)) {
          setUnitSelectionStartTime(
            moment(booking.appointments[0]["starting_at"]).format("H:mmA")
          );
          setUnitSelectionEndTime(
            moment(booking.appointments[0]["ending_at"]).format("H:mmA")
          );
          setUnitSelectionVideoUrl(booking.appointments[0].meeting_url);
        }
      });
    }
  }, [opportunity]);

  return (
    <div className="container-fluid mt-3" style={{ marginBottom: 100 }}>
      <div className="row">
        <div className="col">
          <h1 className="title">Unit Selection</h1>
          <span className="sub-info">Total 1 found</span>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-md-4">
          <div className="card shadow">
            <img
              onClick={view}
              style={{ cursor: "pointer" }}
              alt="Bandar Rimbayu Top View"
              src={TopView}
              className="card-img-top"
            />
            <div className="card-body">
              <h5
                onClick={view}
                style={{ cursor: "pointer" }}
                className="card-title"
              >
                Unit Selection for Starling, 12D @ Bandar Rimbayu
              </h5>
              <div className="d-flex"></div>
              <div className="d-flex">
                <div onClick={view} style={{ cursor: "pointer" }}>
                  <div className="d-flex flex-column align-items-center justify-content-center date-box">
                    <span className="month">JAN</span>
                    <span className="date">10</span>
                  </div>
                </div>
                <div
                  onClick={view}
                  style={{ cursor: "pointer" }}
                  className="d-flex ml-2 flex-column justify-content-center"
                >
                  <span className="date-title">Unit Selection</span>
                  <span className="sub-info">
                    {unitSelectionStartTime
                      ? `${unitSelectionStartTime} - ${unitSelectionEndTime}`
                      : "Timeslot to be confirmed"}
                  </span>
                </div>
                {isRegistered ? (
                  <div className="d-flex flex-fill justify-content-end">
                    <div className="dropdown d-flex align-items-center">
                      <button
                        className="btn btn-link"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <img alt="Calendar" src={Calendar} width={24} />
                      </button>
                      <div className="dropdown-menu dropdown-menu-right">
                        <a
                          className="dropdown-item dropdown-option"
                          href="https://www.google.com/calendar/render?action=TEMPLATE&text=Starling%2C%2012D%20%40%20Bandar%20Rimbayu%20Unit%20Selection&dates=20210110/20210111&details=Dear%20Customer%2C%0A%0AA%20link%20to%20a%20video%20conference%20call%20will%20be%20sent%20to%20you.%20Join%20the%20call%20at%20the%20allocated%20time%20on%2010th%20of%20January%202021.%20You%20will%20be%20given%2020%20minutes%20to%20select%20your%20ideal%20unit.%0A%0AWe%20look%20forward%20to%20your%20participation.%0ABandar%20Rimbayu&location=Online%20conference%20call&trp=true&sf=true&output=xml#f"
                          target="_blank"
                        >
                          Google Calendar
                        </a>
                        <a
                          className="dropdown-item dropdown-option"
                          href="https://microsites.mhub.my/ijm/emails/2021-01-10-Bandar_Rimbayu_UnitSelection4.ics"
                          download
                        >
                          Outlook Calendar (.ics)
                        </a>
                        <a
                          className="dropdown-item dropdown-option"
                          href="https://calendar.yahoo.com/?desc=Dear%20Customer%2C%0A%0AA%20link%20to%20a%20video%20conference%20call%20will%20be%20sent%20to%20you.%20Join%20the%20call%20at%20the%20allocated%20time%20on%2010th%20of%20January%202021.%20You%20will%20be%20given%2020%20minutes%20to%20select%20your%20ideal%20unit.%0A%0AWe%20look%20forward%20to%20your%20participation.%0ABandar%20Rimbayu&st=20210110&title=Starling%2C%2012D%20%40%20Bandar%20Rimbayu%20Unit%20Selection&v=60&dur=allday"
                          target="_blank"
                        >
                          Yahoo Calendar
                        </a>
                      </div>
                    </div>
                    {isRegistered && unitSelectionVideoUrl ? (
                      <a
                        className="btn btn-link d-flex align-items-center"
                        target="_blank"
                        href={unitSelectionVideoUrl}
                      >
                        <img alt="Video" src={Video} width={24} />
                      </a>
                    ) : (
                      <div className="btn">
                        <div style={{ width: 24 }}></div>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
              {isRegistered ? (
                <Link
                  to={{ pathname: "/1/detail" }}
                  className="btn blue-button mt-3 py-2"
                >
                  View Project
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Listing;
