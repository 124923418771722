import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
// import Slider from "react-slick";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faCaretLeft,
//   faCaretRight,
//   faFileAlt,
// } from "@fortawesome/free-solid-svg-icons";
import { VirtualTour, ImgBrochure } from "../../../images";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ContactUs from "../../../components/contactUs";
import CountdownTimer from "../../../components/countdownTimer";

// function SampleNextArrow(props) {
//   const { onClick } = props;
//   return (
//     <div onClick={onClick} className="next">
//       <FontAwesomeIcon icon={faCaretRight} />
//     </div>
//   );
// }

// function SamplePrevArrow(props) {
//   const { onClick } = props;
//   return (
//     <div onClick={onClick} className="prev">
//       <FontAwesomeIcon icon={faCaretLeft} />
//     </div>
//   );
// }

function Description() {
  const unlockPricingDate = moment("2022-06-10");
  const location = useLocation();
  useEffect(() => {
    const { scrollToTop } = location;
    if (scrollToTop) {
      window.scrollTo(0, 0);
    }
  }, []);

  // const [activeSlide, activeSlide2] = useState(0);
  // const settings = {
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   fade: true,
  //   cssEase: "linear",
  //   nextArrow: <SampleNextArrow />,
  //   prevArrow: <SamplePrevArrow />,
  //   // beforeChange: (current, next) => this.setState({ activeSlide: next }),
  //   // afterChange: current => this.setState({ activeSlide2: current })
  // };

  return (
    <>
      <div className="row">
        <div className="col-md-9">
          <div className="d-flex flex-column">
            <h1 className="title">Starling, 12D</h1>
            <span className="sub-info">Bandar Rimbayu</span>
            <span className="sub-info-small">Residential</span>
          </div>
          <hr className="mt-4 mb-4" />
        </div>
        <div className="col-md-3 mb-3 mb-md-0">
          <CountdownTimer />
        </div>
      </div>
      <div className="row">
        <div className="col-md-9" style={{ paddingBottom: 14 }}>
          <span className="contact-us">Project Description</span>
          <ul style={{ paddingInlineStart: 20, paddingTop: 15 }}>
            <li className="sub-info">
              Award-winning township connected to 4 major highways
            </li>
            <li className="sub-info">
              High-speed broadband infrastructure ready
            </li>
            <li className="sub-info">Guarded Neighbourhood with 2 entrances</li>
            <li className="sub-info">16 acres of open space</li>
            <li className="sub-info">2km neighbourhood jogging path</li>
            <li className="sub-info">
              Lush green area, with multipurpose court, cloud pavilion,
              amphitheatre, central lawn, feature shelter, plaza, parcourse
            </li>
            <li className="sub-info">
              20' wide column-to-column car park (fits 2 cars)
            </li>
            <li className="sub-info">Premium finishes</li>
          </ul>
          <hr className="mt-4 mb-4" />
          <span className="contact-us">Specifications</span>
          <div className="row">
            <div className="col-md-6 d-flex flex-column">
              <span
                className="upload-image"
                style={{ paddingTop: 23, color: "#596975" }}
              >
                Property Type
              </span>
              <span className="sub-info">Double-Storey Terrace House</span>
              <span
                className="upload-image"
                style={{ paddingTop: 13, color: "#596975" }}
              >
                Built up area
              </span>
              <span className="sub-info">1,621 sqft - 1,802 sqft</span>
              <span
                className="upload-image"
                style={{ paddingTop: 13, color: "#596975" }}
              >
                Tenure
              </span>
              <span className="sub-info">Leasehold</span>
              <span
                className="upload-image"
                style={{ paddingTop: 13, color: "#596975" }}
              >
                Phase
              </span>
              <span className="sub-info">Starling 12D</span>
            </div>
            <div className="col-md-6 d-flex flex-column">
              <span
                className="upload-image"
                style={{ paddingTop: 23, color: "#596975" }}
              >
                Land Area
              </span>
              <span className="sub-info">20' x 60'</span>
              <span
                className="upload-image"
                style={{ paddingTop: 13, color: "#596975" }}
              >
                Total no. of units
              </span>
              <span className="sub-info">183</span>
              <span
                className="upload-image"
                style={{ paddingTop: 13, color: "#596975" }}
              >
                Township
              </span>
              <span className="sub-info">Bandar Rimbayu</span>
              <span
                className="upload-image"
                style={{ paddingTop: 13, color: "#596975" }}
              >
                Completion Date
              </span>
              <span className="sub-info">24 months from SPA</span>
            </div>
          </div>
          <hr className="mt-4 mb-4" />
          <span className="contact-us">Layouts</span>
          <div id="table-box">
            <table className="table-spacing" width="100%">
              <thead>
                <tr>
                  <th className="date-title">Layout Type</th>
                  <th className="date-title">Size (sq ft)</th>
                  {unlockPricingDate.isBefore() ? (
                    <th className="date-title pl-3">Starting Price</th>
                  ) : null}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-title="Layout Type" className="background-grey">
                    <div className="layout-title">
                      Type D1 - INTERMEDIATE (Staircase on Left)
                    </div>
                  </td>
                  <td data-title="Size (sq ft)" className="background-grey">
                    <span className="layout-title">1,769</span>
                  </td>
                  {unlockPricingDate.isBefore() ? (
                    <td data-title="Starting Price" className="background-grey">
                      <span className="layout-title">RM 664,800.00</span>
                    </td>
                  ) : null}
                  {/* <td data-title="Virtual Tour" className="background-grey">
                    <a
                      target="_blank"
                      href="https://360vr.my/ijmland/rimbayu_starling/"
                      className="virtual-tour"
                    >
                      <img src={VirtualTour} width={20} /> Virtual tour
                    </a>
                  </td> */}
                </tr>
                <tr>
                  <td data-title="Layout Type" className="background-grey">
                    <div className="layout-title">
                      Type D1 - INTERMEDIATE (Staircase on Right)
                    </div>
                  </td>
                  <td data-title="Size (sq ft)" className="background-grey">
                    <span className="layout-title">1,769</span>
                  </td>
                  {unlockPricingDate.isBefore() ? (
                    <td data-title="Starting Price" className="background-grey">
                      <span className="layout-title">RM 664,800.00</span>
                    </td>
                  ) : null}
                  {/* <td data-title="Virtual Tour" className="background-grey">
                    <a
                      target="_blank"
                      href="https://360vr.my/ijmland/rimbayu_starling/"
                      className="virtual-tour"
                    >
                      <img src={VirtualTour} width={20} /> Virtual tour
                    </a>
                  </td> */}
                </tr>
                <tr>
                  <td data-title="Layout Type" className="background-grey">
                    <div className="layout-title">
                      Type D2 - END LOT (Staircase on Left)
                    </div>
                  </td>
                  <td data-title="Size (sq ft)" className="background-grey">
                    <span className="layout-title">1,769</span>
                  </td>
                  {unlockPricingDate.isBefore() ? (
                    <td data-title="Starting Price" className="background-grey">
                      <span className="layout-title">RM 721,800.00</span>
                    </td>
                  ) : null}
                  {/* <td data-title="Virtual Tour" className="background-grey">
                    <a
                      target="_blank"
                      href="https://360vr.my/ijmland/rimbayu_starling/"
                      className="virtual-tour"
                    >
                      <img src={VirtualTour} width={20} /> Virtual tour
                    </a>
                  </td> */}
                </tr>
                <tr>
                  <td data-title="Layout Type" className="background-grey">
                    <div className="layout-title">
                      Type D2 - END LOT (Staircase on Right)
                    </div>
                  </td>
                  <td data-title="Size (sq ft)" className="background-grey">
                    <span className="layout-title">1,769</span>
                  </td>
                  {unlockPricingDate.isBefore() ? (
                    <td data-title="Starting Price" className="background-grey">
                      <span className="layout-title">RM 721,800.00</span>
                    </td>
                  ) : null}
                  {/* <td data-title="Virtual Tour" className="background-grey">
                    <a
                      target="_blank"
                      href="https://360vr.my/ijmland/rimbayu_starling/"
                      className="virtual-tour"
                    >
                      <img src={VirtualTour} width={20} /> Virtual tour
                    </a>
                  </td> */}
                </tr>
                <tr>
                  <td data-title="Layout Type" className="background-grey">
                    <div className="layout-title">
                      Type D3 - CORNER (Staircase on Left)
                    </div>
                  </td>
                  <td data-title="Size (sq ft)" className="background-grey">
                    <span className="layout-title">1,769</span>
                  </td>
                  {unlockPricingDate.isBefore() ? (
                    <td data-title="Starting Price" className="background-grey">
                      <span className="layout-title">RM 843,800.00</span>
                    </td>
                  ) : null}
                  {/* <td data-title="Virtual Tour" className="background-grey">
                    <a
                      target="_blank"
                      href="https://360vr.my/ijmland/rimbayu_starling/"
                      className="virtual-tour"
                    >
                      <img src={VirtualTour} width={20} /> Virtual tour
                    </a>
                  </td> */}
                </tr>
                <tr>
                  <td data-title="Layout Type" className="background-grey">
                    <div className="layout-title">
                      Type D3 - CORNER (Staircase on Right)
                    </div>
                  </td>
                  <td data-title="Size (sq ft)" className="background-grey">
                    <span className="layout-title">1,769</span>
                  </td>
                  {unlockPricingDate.isBefore() ? (
                    <td data-title="Starting Price" className="background-grey">
                      <span className="layout-title">RM 809,800.00</span>
                    </td>
                  ) : null}
                  {/* <td data-title="Virtual Tour" className="background-grey">
                    <a
                      target="_blank"
                      href="https://360vr.my/ijmland/rimbayu_starling/"
                      className="virtual-tour"
                    >
                      <img src={VirtualTour} width={20} /> Virtual tour
                    </a>
                  </td> */}
                </tr>
              </tbody>
            </table>
          </div>
          <hr className="mt-4 mb-4" />
          <span className="contact-us">Documents</span>
          <div className="row mt-3">
            <div className="col-md-4 col-12">
              <a
                href={`${process.env.PUBLIC_URL}/images/Phase12D_Starling.pdf`}
                target="_blank"
              >
                <img className="img-fluid" src={ImgBrochure} />
              </a>
              {/* <div className="card shadow">
                <div className="card-body">
                  <Slider {...settings}>
                    <div>
                      <img alt="map" src={Map} className="img-fluid" />
                    </div>
                    <div>
                      <img alt="map" src={Map} className="img-fluid" />
                    </div>
                  </Slider>
                  <span className="image-counter">1 / 12</span>
                </div>
                <div className="card-footer">
                  <span className="document-name">
                    <FontAwesomeIcon
                      icon={faFileAlt}
                      style={{ fontSize: 14, marginRight: 4 }}
                    />{" "}
                    Jewels-grasmere
                  </span>
                  <span
                    className="document-name"
                    style={{ float: "right", paddingTop: 4 }}
                  >
                    4.67mb
                  </span>
                </div>
              </div> */}
            </div>
          </div>
          <hr className="mt-4 mb-4" />
          <div className="d-flex flex-column">
            <span className="contact-us">Location</span>
            <span className="sub-info" style={{ paddingTop: 13 }}>
              Starling, Jalan Fauna 1, Bandar Rimbayu, 42500 Telok Panglima
              Garang, Selangor
            </span>
            {/* <img
              alt="map"
              src={Map}
              style={{ paddingTop: 15, width: 530 }}
              className="img-fluid"
            /> */}
          </div>
        </div>
        <div className="col-md-3 mt-3 mt-md-0">
          <ContactUs />
        </div>
      </div>
    </>
  );
}

export default Description;
