import React, { useContext } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Registration,
  Balloting,
  Email,
  UnitSelection,
  DepositPayment,
  SPA,
  EditPen,
} from "../../../images";
import ContactUs from "../../../components/contactUs";
import CountdownTimer from "../../../components/countdownTimer";
import Contexts from "../../../contexts";
import BallotingTime from "../../../components/ballotingTime";
import UnitSelectionTime from "../../../components/unitSelectionTime";

function OnlineBalotting() {
  const { isRegistered } = useContext(Contexts);
  const lockRegistrationButton = moment("2021-01-10");
  const ballotingEndDate = moment("2021-01-12");

  if (ballotingEndDate.isBefore()) {
    return (
      <div className="row" style={{ marginTop: "3rem" }}>
        <div className="col d-flex flex-column">
          <h1 className="title text-center">Ended</h1>
          <div className="text-center mt-3">
            <span className="sub-info">
              Unit Selection for Starling, 12D @ Bandar Rimbayu
              was held on <b>10th Jan 2021</b>
            </span>
          </div>
          <div className="text-center">
            <Link to="/" className="btn grey-outline-button mt-4 py-2">
              Back to All balloting events
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="row">
        <div className="col-md-9">
          <h1 className="title">
            Unit Selection for Starling, 12D @ Bandar Rimbayu
          </h1>
          <div className="mt-1">
            <UnitSelectionTime />
          </div>
        </div>
        <div className="col-md-3 mt-4 mt-md-0 d-flex flex-column">
          <CountdownTimer />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-3 mb-3">
          <hr />
        </div>
      </div>
      <div className="row">
        <div className="col-md-9">
          <span className="sub-info">
            We are pleased to invite you to register for an entry for{" "}
            <span className="sub-info-bold">
              Unit Selection for Starling Phase 12D @ Bandar Rimbayu.
            </span>
            <br />
            <br />
            Just follow these steps to own your preferred unit - Starling, 12D @
            Bandar Rimbayu.
          </span>
          <div className="mt-3">
            <img
              alt="Email Notification"
              src={Email}
              width="25px"
              className="mt-4"
            />
            <div className="d-flex flex-column">
              <span className="sub-info-title">Email Notification</span>
              <span className="sub-info" style={{ paddingTop: 3 }}>
                An email will be sent stating your time slot for the unit
                selection. You may also check your timeslot on this page.
              </span>
            </div>
          </div>
          <div>
            <img
              alt="Unit Selection"
              src={UnitSelection}
              width="25px"
              className="mt-4"
            />
            <div className="d-flex flex-column">
              <span className="sub-info-title">Unit Selection</span>
              <span className="sub-info" style={{ paddingTop: 3 }}>
                A link to a video conference call will be sent to you. Join the
                call at the allocated time on 10th of Jan 2021. You will be
                given 5 minutes to select your ideal unit.
              </span>
            </div>
          </div>
          <div>
            <img
              alt="Deposit Payment"
              src={DepositPayment}
              width="25px"
              className="mt-4"
            />
            <div className="d-flex flex-column">
              <span className="sub-info-title">Deposit Payment</span>
              <span className="sub-info" style={{ paddingTop: 3 }}>
                If the deposit has not been paid, you may pay with
                cash/cheque/credit card in The Gallerie @ Rimbayu. Please be
                reminded to bring along your voucher(s), if any, to complete the
                booking process.
              </span>
            </div>
          </div>
          <div>
            <img
              alt="Email Notification"
              src={Email}
              width="25px"
              className="mt-4"
            />
            <div className="d-flex flex-column">
              <span className="sub-info-title">Email Notification</span>
              <span className="sub-info" style={{ paddingTop: 3 }}>
                A confirmation email will be sent to you to confirm the unit you
                have successfully selected.
              </span>
            </div>
          </div>
          <div>
            <img alt="SPA Signing" src={SPA} width="25px" className="mt-4" />
            <div className="d-flex flex-column">
              <span className="sub-info-title">
                Sales and Purchase Agreement Signing
              </span>
              <span className="sub-info" style={{ paddingTop: 3 }}>
                Lastly, our lawyer will contact you to arrange for a Sales and
                Purchase Agreement signing.
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-3 mt-4 mt-md-0">
          <ContactUs />
        </div>
      </div>
    </>
  );
}

export default OnlineBalotting;
