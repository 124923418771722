import React, { useContext, useState, useRef, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import Contexts from "../../contexts";
import { Doc } from "../../images";
import {
  getOpportunities,
  getBookings,
  getBookingTemplate,
} from "../../libs/api";

const mapStatusColor = {
  booked: "success",
  sold_spa_signed: "success",
  paid: "success",
  reserved: "success",
  "Partially Paid": "success",
  Verified: "success",
  "Pending Verification": "warning",
  Unpaid: "danger",
  "Payment Failed": "danger",
  Rejected: "danger",
  cancelled: "cancelled",
  cancelled_developer: "cancelled",
  Expired: "cancelled",
};
const mapStatusText = {
  booked: "booked",
  sold_spa_signed: "sold",
  paid: "paid",
  reserved: "reserved",
  "partially paid": "Partially Paid",
  verified: "verified",
  "pending verification": "pending verification",
  unpaid: "unpaid",
  "payment failed": "payment failed",
  rejected: "rejected",
  cancelled: "cancelled",
  cancelled_developer: "cancelled",
  expired: "expired",
};

export default function Bookings() {
  const { user } = useContext(Contexts);
  const [opportunities, setOpportunities] = useState([]);
  const [html, setHtml] = useState("");
  const modalButton = useRef();
  useEffect(() => {
    const init = async () => {
      const res = await getOpportunities(
        user.attributes["custom:lead_customer_id"]
      );
      setOpportunities(res);
    };
    init();
  }, []);

  return (
    <div className="container-fluid mt-3" style={{ marginBottom: 100 }}>
      <div className="row">
        <div className="col">
          <h1 className="title">All Bookings</h1>
        </div>
      </div>
      <div className="row">
        {_.map(opportunities, (booking) => {
          if (
            booking.booking_id &&
            _.includes(
              ["booked", "sold_spa_signed", "cancelled_developer", "reserved"],
              booking.booking_status_id
            )
          ) {
            return (
              <Booking
                key={booking.id}
                booking={booking}
                setHtml={setHtml}
                modalButton={modalButton}
              />
            );
          }
        })}
      </div>
    </div>
  );
}

function Booking({ booking, setHtml, modalButton }) {
  const { user } = useContext(Contexts);
  const [printables, setPrintables] = useState([]);

  async function getTemplates() {
    const data = await getBookings(
      user.attributes["custom:company_id"],
      booking.booking_id
    );
    setPrintables(data);
  }

  async function print(template_id) {
    const template = await getBookingTemplate(
      user.attributes["custom:company_id"],
      booking.booking_id,
      template_id
    );

    const newWindow = window.open("", "_blank");
    try {
      newWindow.document.write(template);
    } catch {
      //do something
    }
    // setHtml(template);
    // modalButton.current.click();
  }

  useEffect(() => {
    getTemplates();
  }, [booking]);

  return (
    <div className="col-md-4 mt-4">
      <div className="booking-card">
        <div className="booking-card-header">
          <div className="d-flex mt-2">
            <div className="title mr-auto">{booking.unit_number}</div>
            {booking.booking_status_id != "cancelled_developer" ? (
              <div className="dropdown">
                <img
                  className="dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  src={Doc}
                  width={16}
                  height={20}
                  style={{ cursor: "pointer" }}
                />
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownMenuButton"
                >
                  {_.map(printables, (printable) => {
                    return (
                      <button
                        key={printable.id}
                        onClick={() => print(printable.id)}
                        className="dropdown-item dropdown-doc-option"
                        href="#"
                      >
                        Booking Form
                        {/* {printable.name} */}
                      </button>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
          <div className="sub-title">{booking.project_name}</div>
        </div>
        <div className="booking-card-body">
          <div className="d-flex my-2">
            <div className="title mr-auto">Date</div>
            <div className="info">
              {moment(booking.booking_created_at).format("D MMM YYYY")}
            </div>
          </div>
          <div className="d-flex my-2">
            <div className="title mr-auto">Unit selling price</div>
            <div className="info">
              RM {Number(booking.booking_spa_value).toLocaleString()}
            </div>
          </div>
          <div className="d-flex mt-3">
            <div
              className={`booking-status booking-${
                mapStatusColor[booking.booking_status_id]
              } d-flex justify-content-center`}
            >
              <span className="text-capitalize">
                {mapStatusText[booking.booking_status_id]}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
