import React, { useState, useContext, useEffect, useRef } from "react";
import _ from "lodash";
import moment from "moment";
import Contexts from "../../../contexts";
import { Doc } from "../../../images";
import { getBookings, getBookingTemplate } from "../../../libs/api";

const mapStatusColor = {
  booked: "success",
  sold_spa_signed: "success",
  paid: "success",
  reserved: "success",
  "Partially Paid": "success",
  Verified: "success",
  "Pending Verification": "warning",
  Unpaid: "danger",
  "Payment Failed": "danger",
  Rejected: "danger",
  cancelled: "cancelled",
  cancelled_developer: "cancelled",
  Expired: "cancelled",
};
const mapStatusText = {
  booked: "booked",
  sold_spa_signed: "sold",
  paid: "paid",
  reserved: "reserved",
  "partially paid": "Partially Paid",
  verified: "verified",
  "pending verification": "pending verification",
  unpaid: "unpaid",
  "payment failed": "payment failed",
  rejected: "rejected",
  cancelled: "cancelled",
  cancelled_developer: "cancelled",
  expired: "expired",
};

function Bookings() {
  const [html, setHtml] = useState("");
  const modalButton = useRef();
  const { opportunity } = useContext(Contexts);

  if (
    _.isEmpty(opportunity) ||
    _.isEmpty(_.filter(opportunity, "booking_id"))
  ) {
    return (
      <div className="row" style={{ marginTop: "3rem" }}>
        <div className="col d-flex flex-column">
          <h1 className="title text-center">No booking found</h1>
          <div className="text-center mt-3">
            <span className="sub-info">
              We request for your patience until we update your booking
              information here.
            </span>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="row">
        <button
          type="button"
          ref={modalButton}
          className="btn btn-primary d-none"
          data-toggle="modal"
          data-target="#mymodal"
        >
          Large modal
        </button>
        <div
          id="mymodal"
          className="modal fade bd-example-modal-lg "
          tabIndex="-1"
        >
          <div className="modal-dialog modal-lg h-100">
            <div className="modal-content h-100">
              <iframe srcDoc={html} height="100%" />
            </div>
          </div>
        </div>
        <div className="col-12">
          <h1 className="booking-title">My Bookings</h1>
        </div>
        {_.map(opportunity, (booking) => {
          if (
            booking.booking_id &&
            _.includes(
              ["booked", "sold_spa_signed", "cancelled_developer", "reserved"],
              booking.booking_status_id
            )
          ) {
            return (
              <Booking
                key={booking.id}
                booking={booking}
                setHtml={setHtml}
                modalButton={modalButton}
              />
            );
          }
        })}
      </div>
    );
  }
}

function Booking({ booking, setHtml, modalButton }) {
  const { user } = useContext(Contexts);
  const [printables, setPrintables] = useState([]);

  async function getTemplates() {
    const data = await getBookings(
      user.attributes["custom:company_id"],
      booking.booking_id
    );
    setPrintables(data);
  }

  async function print(template_id) {
    const template = await getBookingTemplate(
      user.attributes["custom:company_id"],
      booking.booking_id,
      template_id
    );

    const newWindow = window.open("", "_blank");
    try {
      newWindow.document.write(template);
    } catch {
      //do something
    }
    // setHtml(template);
    // modalButton.current.click();
  }

  useEffect(() => {
    getTemplates();
  }, [booking]);

  return (
    <div className="col-md-4 mt-4">
      <div className="booking-card">
        <div className="booking-card-header">
          <div className="d-flex mt-2">
            <div className="title mr-auto">{booking.unit_number}</div>
            {booking.booking_status_id != "cancelled_developer" ? (
              <div className="dropdown">
                <img
                  className="dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  src={Doc}
                  width={16}
                  height={20}
                  style={{ cursor: "pointer" }}
                />
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownMenuButton"
                >
                  {_.map(printables, (printable) => {
                    return (
                      <button
                        key={printable.id}
                        onClick={() => print(printable.id)}
                        className="dropdown-item dropdown-doc-option"
                        href="#"
                      >
                        Booking Form
                        {/* {printable.name} */}
                      </button>
                    );
                  })}
                </div>
              </div>
            ) : null}
          </div>
          <div className="sub-title">{booking.project_name}</div>
        </div>
        <div className="booking-card-body">
          <div className="d-flex my-2">
            <div className="title mr-auto">Date</div>
            <div className="info">
              {moment(booking.booking_created_at).format("D MMM YYYY")}
            </div>
          </div>
          <div className="d-flex my-2">
            <div className="title mr-auto">Unit selling price</div>
            <div className="info">
              RM {Number(booking.booking_spa_value).toLocaleString()}
            </div>
          </div>
          <div className="d-flex mt-3">
            <div
              className={`booking-status booking-${
                mapStatusColor[booking.booking_status_id]
              } d-flex justify-content-center`}
            >
              <span className="text-capitalize">
                {mapStatusText[booking.booking_status_id]}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bookings;
