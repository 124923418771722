import React from "react";
import IntlTelInput from "react-intl-tel-input";

function EmergencyContact({ emergency_contact, _onEmergencyContactChange }) {
  return (
    <div className="row mt-3">
      <div className="col-12">
        <div className="sub-title mt-5">Emergency contact</div>
        <div className="row mt-4">
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-title">
                Name of emergency contact <span className="asterisk">*</span>
              </label>
              <input
                required
                type="text"
                className="form-control"
                name="name"
                value={emergency_contact.name}
                onChange={(e) => _onEmergencyContactChange(e.target.name, e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-title">
                Relationship of emergency contact
                <span className="asterisk"> *</span>
              </label>
              <input
                required
                type="text"
                className="form-control"
                name="relation"
                value={emergency_contact.relation}
                onChange={(e) => _onEmergencyContactChange(e.target.name, e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-title">
                Emergency contact number <span className="asterisk">*</span>
              </label>
              <IntlTelInput
                formatOnInit={false}
                placeholder="123456789"
                containerClassName="intl-tel-input"
                inputClassName="form-control"
                preferredCountries={["my"]}
                separateDialCode={true}
                value={emergency_contact.mobile}
                onPhoneNumberChange={(validation, number, country) =>
                  _onEmergencyContactChange(
                    "mobile",
                    {
                      mobile_prefix: country.dialCode,
                      mobile_country_code: country.iso2.toUpperCase(),
                      mobile: number,
                    }
                  )
                }
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-title">
                Emergency contact email <span className="asterisk">*</span>
              </label>
              <input
                required
                type="email"
                className="form-control"
                name="email"
                value={emergency_contact.email}
                onChange={(e) => _onEmergencyContactChange(e.target.name, e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label className="label-title">
                Emergency Identity number <span className="asterisk">*</span>
              </label>
              <input
                required
                className="form-control"
                name="nric"
                value={emergency_contact.nric}
                onChange={(e) => _onEmergencyContactChange(e.target.name, e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmergencyContact;
